import { Api } from 'lib/api/instance'

import Countries from '@/models/collections/Countries'
import Languages from '@/models/collections/Languages'
import Locales from '@/models/collections/Locales'

export default {
  namespaced: true,

  state: {
    countries: new Countries(),
    languages: new Languages(),
    locales: new Locales(),
    genders: [],
    currencies: [],
    tags: [],
    timezones: [],
    advertisingCampaignsCategories: [],
    advertisingCampaignsPositions: [],
    campaignDictionaries: {},
    exchangeRates: [],
    minimumPaymentAmounts: [],
    dictsFetching: false,
    affiliateNetworks: [],
  },

  mutations: {
    setCampaignDictionaries (state, payload) {
      const formatted = Object.keys(payload).reduce((acc, key) => {
        const dictionary = payload[key]

        switch (key) {
          case 'agencies_payable_cpm':
          case 'agencies_payable_cpa':
          case 'agencies_payable_cpc':
            return {
              ...acc,
              [key]: dictionary,
            }
        }
        if (Array.isArray(dictionary)) {
          return {
            ...acc,
            [key]: dictionary,
          }
        }
        if (typeof dictionary === 'object' && dictionary !== null) {
          return {
            ...acc,
            [key]: Object.keys(dictionary).map(value => ({
              id: value,
              label: dictionary[value],
            })),
          }
        }
        return acc
      }, {})
      state.campaignDictionaries = {
        ...state.campaignDictionaries,
        ...formatted,
      }
    },

    setCurrencies (state, payload) {
      state.currencies = payload
    },

    setTags (state, payload) {
      state.tags = payload
    },

    setTimezones (state, payload) {
      state.timezones = payload
    },

    setCountries (state, payload) {
      state.countries.remove({})
      state.countries.add(payload)
    },

    setGenders (state, payload) {
      state.genders = payload
    },

    setLanguages (state, payload) {
      state.languages.remove({})
      state.languages.add(payload)
    },

    setLocales (state, payload) {
      state.locales.remove({})
      state.locales.add(payload)
    },

    setExchangeRates (state, payload) {
      state.exchangeRates = payload
    },

    setMinimumPaymentAmounts (state, payload) {
      state.minimumPaymentAmounts = payload
    },

    setDictsFething (state, payload) {
      state.dictsFetching = payload
    },
  },

  actions: {
    async fetchDictionaries ({ commit }) {
      const locale = localStorage.getItem('locale') || process.env.VUE_APP_DEFAULT_LOCALE

      commit('setDictsFething', true)
      const res = await Api.get('dictionary/all', { locale })
      commit('setCampaignDictionaries', {
        categories: res.campaigns_categories,
      })
      commit('setCampaignDictionaries', {
        adPositions: res.campaigns_positions,
        leaderboardPositions: res.leaderboard_positions,
      })
      commit('setCountries', res.countries)
      commit('setGenders', res.gender)
      commit('setCurrencies', res.currencies)
      commit('setLanguages', res.languages)
      commit('setLocales', res.locales)
      commit('setTags', res.tags)
      commit('setTimezones', res.time_zones)
      commit('setExchangeRates', res.exchange_rates)
      commit('setMinimumPaymentAmounts', res.payment_minimum_amount)
      commit('setDictsFething', false)
    },

    async fetchCampaignDictionaries ({ commit }) {
      const locale = localStorage.getItem('locale') || process.env.VUE_APP_FALLBACK_LOCALE

      const res = await Api.get('partner/campaigns/dictionaries', { locale })
      delete res.status
      commit('setCampaignDictionaries', res)
    },

    async fetchAwarenessCampaignDictionaries ({ commit }) {
      const locale = localStorage.getItem('locale') || process.env.VUE_APP_FALLBACK_LOCALE

      const res = await Api.get('partner/campaigns/brand_awareness/dictionaries', { locale })
      delete res.status
      commit('setCampaignDictionaries', res)
    },
  },
  getters: {
    advertisingCampaignsCategories: state => state.advertisingCampaignsCategories,
    advertisingCampaignsPositions: state => state.advertisingCampaignsPositions,
    campaignDictionaries: state => state.campaignDictionaries,
    currencies: state => state.currencies,
    genders: state => state.genders,
    tags: state => state.tags,
    languages: state => state.languages.models,
    countries: state => state.countries.models,
    locales: state => state.locales.models,
    exchangeRates: state => state.exchangeRates,
    timezones: state => state.timezones,
    agencies: state => state.campaignDictionaries.agencies,
    strategyPaymentTypes: state => state.campaignDictionaries.strategy_payment_types,
    agenciesPayableCPM: state => state.campaignDictionaries.agencies_payable_cpm,
    agenciesPayableCPA: state => state.campaignDictionaries.agencies_payable_cpa,
    agenciesPayableCPС: state => state.campaignDictionaries.agencies_payable_cpc,
    minimumPaymentAmounts: state => state.minimumPaymentAmounts,
    isDictsFetching: state => state.dictsFetching,
    affiliateNetworks: state => state.campaignDictionaries.affiliate_networks,
  },
}
