import { LocaleDictionary } from 'locales/locale-dictionary'

const dictionary: LocaleDictionary = {
  placeholder: {
    email: 'Почта',
    password: 'Пароль',
    currentPassword: 'Текущий пароль',
    newPassword: 'Новый пароль',
    repeatPassword: 'Повторите пароль',
    phoneNumber: 'Телефон',
    translationLanguage: 'Язык трансляции',
    country: 'Страна',
    city: 'Город',
    enterCity: 'Введите название города',
    state: 'Регион',
    enterState: 'Введите регион',
    zip: 'Индекс',
    address: 'Адрес',
    enterAddress: 'Введите адрес проживания',
    gender: 'Пол',
    birthYear: 'Год рождения',
    birthday: 'Дата рождения',
    name: 'Имя',
    fullname: 'Полное имя',
    enterName: 'Введите имя',
    lastname: 'Фамилия',
    enterLastname: 'Введите фамилию',
    middlename: 'Отчество',
    enterMiddlename: 'Введите отчество',
    companyName: 'Название компании',
    contactPerson: 'Контактное лицо',
    commonCurrency: 'Основная валюта',
    campaign: {
      type: 'Тип кампании',
      name: 'Название кампании',
    },
    inn: 'ИНН',
    iban: 'Номер счета',
    bic: 'БИК',
    paypalEmail: 'Введите почту привязанную к PayPal',
    selectDate: 'Выберите дату',
    chooseCategories: 'Выберите категории',
    optional: 'необязательно',
    passportNumber: 'Passport Number', //!
    panCard: 'PAN card', //!
    panCardIfUpload: 'If you upload PAN card we deduct 10% TDS otherwise 20% TDS', //!
    panCardNumber: 'PAN card Number', //!
    enterPassportNumber: 'Enter PN', //!
    enterPanCardNumber: 'Enter PAN', //!
    payoutMode: 'Payout Mode', //!
    choosePayoutMode: 'Choose Payout Mode', //!
    accountVpa: 'Account VPA', //!
    bankIFSC: 'Bank IFSC', //!
    beneficiaryAccountNumber: 'Beneficiary account number', //!
    partnerSearch: 'Поиск по ID или названию',
    choose: 'Выберите',
    excludeSelected: 'Исключить выбранное',
    dragAFile: 'Перетащите файл',
    dragImageHint: 'JPG или PNG, размер {size} MB или меньше',
  },
  partnerSearchFilter: {
    add: 'Фильтр',
    status: 'Статус',
    platform: 'Платформа',
    advertiser: 'Рекламодатель',
    allAdvertisers: 'Все рекламодатели',
  },
  button: {
    signIn: 'Войти',
    save: 'Сохранить',
    saving: 'Сохранение ..',
    saved: 'Сохранено',
    send: {
      static: 'Отправить',
      pending: 'Отправка ..',
      success: 'Отправлено',
    },
    requestHasBeenSent: 'Заявка отправлена',
    recoverySend: 'Отправить ссылку для восстановления',
    recoverySent: 'Ссылка отправлена',
    passwordChanged: 'Пароль изменен',
    runAd: 'Запустить кампании',
    toLink: 'Привязать',
    remove: 'Удалить',
    delete: {
      static: 'Удалить',
      process: 'Удаление',
      success: 'Удалено',
      successFeminine: 'Удалена',
      successMasculine: 'Удален',
      successPlural: 'Удалены',
    },
    create: {
      campaigns: 'Создать кампанию',
      groups: 'Создать группу',
      creatives: 'Создать креатив',
    },
    enable: 'Включить',
    disable: 'Выключить',
    sendPreview: {
      static: 'Отправить превью',
      success: 'Превью отправлено',
    },
    chooseFile: {
      static: 'Выбрать файл',
    },
    back: {
      static: 'Вернуться',
    },
    attach: {
      static: 'Привязать',
    },
    join: {
      static: 'Присоединиться к',
    },
    cancel: {
      static: 'Отменить',
    },
    next: {
      static: 'Далее',
    },
    resetLink: 'Сбросить ссылку',
    resetQR: 'Сбросить QR-код',
    showQR: 'Показать QR-код',
    hideQR: 'Скрыть QR-код',
    showManager: 'Open Mobile Manager',
    copyLink: {
      static: 'Копировать ссылку',
      success: 'Ссылка скопирована',
    },
    copy: {
      static: 'Copy',
      success: 'Copied',
    },
    saveChanges: {
      static: 'Сохранить изменения',
      pending: 'Сохранение ..',
      success: 'Изменения сохранены',
    },
    getStarted: 'Get started',
    copyFromExisting: 'Скопировать из готового',
    loadMore: 'Загрузить ещё',
  },
  validator: {
    required: 'Обязательное поле',
    invalidEmail: 'Неправильный формат почты',
    invalidPhoneNumber: 'Неправильный формат номера телефона',
    birthYearLess: 'Год должен быть меньше',
    birthYearMore: 'Год должен быть больше',
    shortPassword: 'Пароль должен быть длиннее 8 символов',
    matchPassword: 'Пароли не совпадают',
    positiveNum: 'Введите положительное число',
    alphanumeric: 'Разрешены только буквы и цифры',
    inn: 'Некорректный ИНН',
    bic: 'Некорректный БИК',
    swiftAllowedCharacters: 'SWIFT код может содержать только буквы и цифры',
    swiftLengthDescription: 'SWIFT код должен быть длиной 8 или 11 символов',
    numbersAndSpaces: 'Неправильный формат',
    userInnNotFound: 'Самозанятый с таким ИНН не найден',
    bankAccount: 'Введите 20 цифр без пробелов',
    onlyCyrillic: 'Введите значение на кириллице',
    urlIsInvalid: 'Недопустимое значение URL-адреса',
    embed: 'Введите валидную embed ссылку',
    youtubeUrl: 'Неправильная Youtube-ссылка',
    uniqueYoutubeUrl: 'Вы уже добавили видео с этим id',
  },
  helpers: {
    perHour: 'Раз в 1 час',
    perMin: 'Раз в {minutes} мин',
    seconds: '{n} секунд | {n} секунда | {n} секунды | {n} секунд',
    streamers: '{n} авторов | {n} автор | {n} автора | {n} авторов',
    inSeconds: 'через {n} секунд | через {n} секунду | через {n} секунды | через {n} секунд',
    someOfSome: '{0} из {1}',
    timeAgo: {
      mins: '{n} минут назад | {n} минута назад | {n} минуты назад | {n} минут назад',
      minutes: '{n} минут назад | {n} минута назад | {n} минуты назад | {n} минут назад',
      hours: '{n} часов назад | {n} час назад | {n} часа назад | {n} часов назад',
      days: '{n} дней назад | {n} день назад | {n} дня назад | {n} дней назад',
      months: '{n} месяцев назад | {n} месяц назад | {n} месяца назад | {n} месяцев назад',
      years: '{n} лет назад | {n} год назад | {n} года назад | {n} лет назад',
    },
    timeLeft: {
      mins: '{n} минут осталось | {n} минута осталась | {n} минуты осталось | {n} минут осталось',
      minutes: '{n} минут осталось | {n} минута осталась | {n} минуты осталось | {n} минут осталось',
      hours: '{n} часов осталось | {n} час остался | {n} часа осталось | {n} часов осталось',
      days: '{n} дней осталось | {n} день остался | {n} дня осталось | {n} дней осталось',
      months: '{n} месяцев осталось | {n} месяц остался | {n} месяца осталось | {n} месяцев осталось',
      years: '{n} лет осталось | {n} год остался | {n} года осталось | {n} лет осталось',
    },
  },
  pagination: {
    next: 'Вперед',
    prev: 'Назад',
  },
  sidebar: {
    navigation: {
      streamer: {
        home: {
          title: 'Главная',
        },
        dashboard: {
          title: 'Дэшборд',
        },
        freemium: {
          title: 'Uplify Link',
        },
        campaigns: {
          title: 'Кампании',
          subitems: {
            'campaigns-livestream': 'Livestream',
            'campaigns-preroll': 'Preroll',
          },
        },
        wallet: {
          title: 'Кошелек',
        },
        widget: {
          title: 'Настройки',
        },
        referrals: {
          title: 'Рефералы',
        },
        extension: {
          title: 'Расширение',
        },
        debug: {
          title: 'Debug',
        },
      },
      partner: {
        campaigns: {
          title: 'Кампании',
          subitems: {
            'brand-awareness': 'Спонсорство',
            performance: 'Интерактив',
            preroll: 'Preroll',
          },
        },
        advertisers: {
          title: 'Рекламодатели',
        },
        holdings: {
          title: 'Холдинги',
        },
        creators: {
          title: 'Агентство',
        },
        debug: {
          title: 'Debug',
        },
      },
      profile: {
        settings: 'Настройки профиля',
        logout: 'Выход из Uplify',
      },
    },
    links: {
      help: {
        label: 'Помощь',
        url: 'https://help.uplify.app/?_gl=1*tb7sbb*_ga*MTU4NTg1Mjk4MC4xNjM1MzUxMzA3*_ga_1MG8R911BX*MTY1NjM3MDg4OC4xNjUuMS4xNjU2MzcxNjg1LjQw',
      },
      news: {
        label: 'Что нового',
        url: '',
      },
      feedback: {
        label: 'Обратная связь',
        url: 'https://discord.com/channels/465868803268542475/1118478966467080283',
      },
      discord: {
        label: 'Discord',
        url: 'https://discord.gg/uplify',
      },
    },
    requestDemo: 'Мне нужна помощь',
    socials: {
      title: 'Мы в соцсетях',
    },
  },
  other: {
    adblock: {
      bold: 'Adblock блокирует функционал сайта.',
      normal: 'Пожалуйста, добавьте Uplify в белый список.',
    },
    advice: {
      attention: 'Внимание',
      hint: 'Подсказка',
    },
    uploader: {
      loading: 'Загрузка',
      processing: 'Обработка',
      declined: 'Отклонено',
      fileUploadError: 'Ошибка загрузки файла',
    },
    twitchAlert: 'The site is undergoing technical work. Registration and authorization via Twitch is not available until May 19. See <a href="https://discord.gg/uplify" target="_blank">Discord</a> for details.',
    noData: 'Нет данных',
    enabled: 'Включено',
    disabled: 'Выключено',
    timeAgo: {
      timeAgoMins: '{n} minutes ago',
      timeAgoHours: '{n} hours ago',
      timeAgoDays: '{n} days ago',
      timeAgoMonths: '{n} months ago',
      timeAgoYears: '{n} year ago',
    },
  },
  phrases: {
    terms: 'Правила',
    privacy: 'Конфиденциальность',
    help: 'Помощь',
    loading: 'Загрузка',
    from: 'От',
    to: 'До',
  },
  closeDialog: {
    heading: 'Данные не сохранены',
    text: 'Если вы закроете окно, то ранее введённые данные удаляться.',
    btnSave: 'Продолжить редактирование',
    btnContinue: 'Покинуть без сохранения',
  },
  links: {
    discord: 'https://discord.gg/uplify',
    terms: 'https://www.uplify.app/legal/terms-ru',
    termsLink: 'https://www.uplify.app/legal/link-terms-ru',
    privacy: 'https://www.uplify.app/legal/privacy-policy-ru',
    howIsItCalculated: 'https://www.youtube.com/watch?v=yxWwBOGZhjs&list=PLd-DxFXCIaAz3RnzDN6wYBjMw24QF9uzT&index=8',
    howToIncreaseCtr: 'https://www.youtube.com/watch?v=yxWwBOGZhjs&list=PLd-DxFXCIaAz3RnzDN6wYBjMw24QF9uzT&index=8',
    whenWillIReceiveMyPayouts: 'https://help.uplify.app/en/articles/9173165-%D0%BA%D0%B0%D0%BA-%D0%B2%D1%8B%D0%B2%D0%B5%D1%81%D1%82%D0%B8-%D1%81%D0%B2%D0%BE%D0%B9-%D0%B4%D0%BE%D1%85%D0%BE%D0%B4',
    learnMoreAboutComissions: 'https://fanatical-fly-511.notion.site/49c912a35efb4c21bd0a227d4a96154e',
    whyDoWeNeedThisData: 'https://fanatical-fly-511.notion.site/49c912a35efb4c21bd0a227d4a96154e',
    widgetSetup: 'https://help.uplify.app/en/articles/9115870-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B6%D0%B5%D1%82%D0%B0',
    chatbotSetup: 'https://help.uplify.app/en/articles/9344560-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D1%87%D0%B0%D1%82-%D0%B1%D0%BE%D1%82%D0%B0',
    chatbotSurvey: 'https://forms.gle/NZ39y41kshwM5bwZ6',
    inStreamAdsFormat: 'https://fanatical-fly-511.notion.site/822c48b78bbd43b49a755f9c4c8b4011',
    whatIsTier: 'https://www.youtube.com/watch?v=yxWwBOGZhjs&list=PLd-DxFXCIaAz3RnzDN6wYBjMw24QF9uzT&index=8',
    whatsNew: 'https://www.uplify.app/blog-categories-ru/obnovleniya',
    whyAreWeCollectingThisData: 'https://fanatical-fly-511.notion.site/3998fd16989b4812a1ec73249f1ddf28',
    twitchExtension: 'https://www.youtube.com/watch?v=Ah4Yj1E30zg&list=PLd-DxFXCIaAz3RnzDN6wYBjMw24QF9uzT&index=6',
    takeSurveyLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd55J_DZO7qGnbpiuSfkbPpcxELPR9Yh9YugjSHpKDbjgw5DQ/viewform?usp=sf_link',
  },
  demoInfo: {
    title: 'Вы находитесь в демо-аккаунте',
    text: 'Некоторые функции не работают и требуют регистрации',
    twitchBtn: 'Войти с помощью Twitch',
    youtubeBtn: 'Войти с помощью YouTube',
    trovoBtn: 'Войти с помощью Trovo',
  },
  progress: {
    of: 'из',
    forecast: 'Прогноз',
  },
  errors: {
    unknown: 'Неизвестная ошибка',
    wrongExtension: 'Неправильное расширение файла',
    fileSize: 'Файл <b>{name}</b> ({size}MB) должен быть меньше чем {maxSize}MB',
  },
  campaignRow: {
    dailyActions: 'Дневных действий',
    dailyActionsLimit: 'Дневной лимит действий',
    dailyImpressions: 'Дневных просмотров',
    dailyLimit: 'Дневной лимит',
    potentialIncome: 'Потенциальный доход',
    yourIncome: 'Твой доход',
    yourCTR: 'Твой CTR',
    averageCTR: 'Средний CTR',
    dateStart: 'Дата начала',
    dateEnd: 'Дата окончания',
    enable: 'Включить',
    disable: 'Выключить',
    attended: 'Участвовало',
    toggling: 'Отправка..',
    tags: {
      undefinedFormat: 'Неизвестный формат',
      dailyLimitDepleted: 'Сегодня лимит исчерпан',
      totalLimitDepleted: 'Сегодня лимит исчерпан',
      setupExtension: 'Настроить расширение',
      checkExtension: 'Проверить статус расширения',
      extension: 'Расширение',
      actionBonus: 'Бонус за действия',
      dailyActionsLimitDepleted: 'Лимит действий исчерпан',
      performanceDailyDepleted: 'Сегодня лимит исчерпан',
    },
    disabledUntil: 'Недоступна до: {date}',
    reasons: {
      lowCTR: '🔥 Причина: низкий CTR',
      lowCTRLink: {
        text: 'Как улучшить мой CTR?',
        href: 'https://www.notion.so/CTR-1-eb41fe74e1f54f8b8ca46e03eedcb22b',
      },
      moderator: 'Причина: решение модератора',
    },
    format: {
      fullscreen: 'Overlay 50%',
      pip: 'Overlay 15%',
    },
    totalImpressions: 'Доступных просмотров',
  },
  campaignSidebar: {
    potentialRevenue: 'Потенциальный доход',
    totalRevenue: 'Твой общий доход',
    revenue: 'Твой доход',
    estimatedRevenue: 'Ожидаемый доход',
    ctr: 'Твой CTR',
    target_ctr: 'Целевой CTR',
    clicks: 'Количество кликов',
    date: 'Дата окончания',
    dateStart: 'Дата начала',
    dateEnd: 'Дата окончания',
    paymentType: 'Тип оплаты',
    pricePerViews: 'Цена за 1000 просмотров',
    pricePerAction: 'Цена за 1 действие',
    pricePerClick: 'Цена за 1 клик',
    frequency: 'Частота просмотров',
    adFormat: 'Формат кампании',
    advertiserCategory: 'Категория рекламодателя',
    description: 'Описание кампании',
    creativePreview: 'Превью креатива',
    messageInDescription: 'Сообщение в описании',
    chatMessage: 'Сообщение в чат-бот',
    campaignOn: 'Кампания включена',
    campaignOff: 'Кампания выключена',
    productLink: 'Ссылка на продукт',
    copyProductLink: 'Копировать вашу ссылку на продукт',
    deliveredAtions: 'Выполненные действия',
    deliveredImpressions: 'Кол-во просмотров',
    deliveredDailyActions: 'Выполненные действия',
    deliveredClicks: 'Клики по ссылке',
    downloadCreative: 'Скачать креатив',
    copyDescription: 'Копировать описание',
    videos: 'Ссылка на видео',
    addNewVideo: 'Добавить ещё одно видео',
  },
  intercom: {
    freemium: 'Привет! Я хочу попробовать Uplify Link',
  },
  views: {
    auth: {
      streamerSignin: {
        title: 'Привет! 👋',
        subtitle: 'Войдите в свой аккаунт, используя одну из учетных записей',
        startWithTwitch: 'Начать с помощью Twitch',
        startWithYoutube: 'Начать с помощью YouTube',
        startWithTrovo: 'Начать с помощью Trovo',
        partnerLogin: 'Вход для партнеров',
        termsAndPolicy: 'Выполняя вход, вы принимаете условия <a href="@:links.terms" target="_blank">Оферты</a>, <a href="@:links.termsLink" target="_blank">Оферты Link</a> и <a href="@:links.privacy" target="_blank">Политики конфиденциальности</a>.',
        accountBlocked: 'Aккаунт заблокирован. Если это ошибка,',
        twitchAuthError: 'Ошибка авторизации Twitch. Повторите попытку или',
        youtubeAuthError: 'Ошибка авторизации Youtube. Повторите попытку или',
        trovoAuthError: 'Ошибка авторизации Trovo. Повторите попытку или',
        discordAuthError: 'Ошибка авторизации Discord. Повторите попытку или',
        accountAlreadyExists: 'Вы пытаетесь подключить платформу, привязанную к другому профилю. ',
        contactSupport: 'напишите в поддержку',
        info: {
          block1: {
            title: 'Uplify помогает авторам монетизировать любой произведенный видео-контент.',
            linkText: 'Узнай больше об Uplify',
            linkHref: 'https://www.uplify.ru/sozdatelyam-kontenta',
            videoHref: 'https://youtu.be/RSXTmPTBehU?si=7fkCpRfuTQJnqqXr',
          },
          block2: {
            title: 'Нам доверяют более <strong>4,000&nbsp;авторов</strong>. Мы&nbsp;выплатили более <strong>100&nbsp;000&nbsp;000 рублей</strong>.',
            linkText: 'Присоединяйся к нам в Telegram',
            linkHref: 'https://t.me/UplifyNews',
          },
          block3: {
            title: '<strong>Спонсорские кампании</strong> от&nbsp;всемирно известных брендов.',
            linkText: 'Смотри наши кейсы',
            linkHref: 'https://www.uplify.ru/cases?utm_source=uplify_ru&utm_medium=button_cases&utm_campaign=website',
          },
        },
      },
      streamerSettings: {
        hi: 'Привет',
        subtitle: 'Осталось совсем чуть-чуть, а пока давайте познакомимся поближе. Это необходимо для корректной работы кампаний.',
      },
      partnerSignin: {
        title: 'Вход для партнеров',
        forgotPassword: 'Забыли пароль?',
        streamerLogin: 'Вход для авторов',
        wannaLaunchAd: 'Хотите запустить спонсорскую кампанию?',
        contactUs: 'Напишите нам для регистрации',
      },
      partnerRequest: {
        partnerSignUp: 'Регистрация партнера',
        partnerSignIn: 'Вход для партнера',
        byStart: 'Нажимая «Отправить», вы принимаете условия',
        terms: 'Пользовательского соглашения',
        and: 'и',
        policy: 'Политики конфиденциальности',
        successMsg: 'Мы отправили инструкцию с дальнейшими шагами на ваш email. Наша команда свяжется с вами в ближайшее время.',
      },
      resetPassword: {
        title: 'Восстановление доступа',
        description: 'Укажите адрес электронной почты, связанный с вашим аккаунтом, и мы пришлем ссылку для сброса пароля.',
        partnerSignIn: 'Вход для партнеров',
      },
      newPassword: {
        title: 'Придумайте пароль',
        descriptionFirstRow: 'Он должен быть не короче 8 символов.',
        descriptionSecondRow: 'Не используйте пароли от других сайтов.',
        successText: 'Пароль cохранён',
        openNow: 'Открыть сейчас',
        pageOpenIn: 'Страница для входа откроется',
      },
      scopeTwitch: {
        heading: 'Перед тем, как начать',
        headline: 'Для корректной работы сервиса Uplify нам необходимо ваше разрешение на&nbsp;доступ к&nbsp;статистике вашего канала и&nbsp;отправку сообщений в&nbsp;чате.',
        info: 'Мы&nbsp;уважаем вашу конфиденциальность и&nbsp;крайне бережно относимся к&nbsp;вашим данным.',
        link: 'Почему мы собираем эти данные?',
        desciptions: [
          {
            title: 'Отправка сообщений в&nbsp;чат',
            text: 'Позволяет отправлять сообщения в&nbsp;чате со&nbsp;ссылкой на&nbsp;сайт рекламодателя.',
          },
          {
            title: 'Получение адреса электронной почты',
            text: 'Email запрашивается Uplify для подтверждения регистрации и&nbsp;отправки важных уведомлений.',
          },
          {
            title: 'Просмотр данных модерации канала, включая настройки Модераторов, Банов, Таймаутов и&nbsp;Автомода',
            text: 'Необходимо для правильной работы чатбота и&nbsp;избежания конфликтов с&nbsp;другими чатботами на&nbsp;канале.',
          },
          {
            title: 'Просмотр конфигурации канала',
            text: 'Доступ только для чтения. Позволяет Uplify оптимизировать рекламные показы.',
          },
          {
            title: 'Просмотр сообщений в&nbsp;чате',
            text: 'Позволяет читать сообщения в&nbsp;чате и&nbsp;проводить интерактивные опросы.',
          },
        ],
        redirectBtn: 'Перейти на Twitch',
      },
      scopeYoutube: {
        heading: 'Перед тем, как начать',
        headline: 'Для корректной работы сервиса Uplify нам необходимо ваше разрешение на&nbsp;доступ к&nbsp;статистике вашего канала и&nbsp;отправку сообщений в&nbsp;чате.',
        info: 'Мы&nbsp;уважаем вашу конфиденциальность и&nbsp;крайне бережно относимся к&nbsp;вашим данным.',
        link: 'Почему мы собираем эти данные?',
        desciptions: [
          {
            title: 'Управление аккаунтом YouTube',
            text: 'Позволяет нам узнать количество активных зрителей на&nbsp;стриме, читать и&nbsp;отправлять сообщения в&nbsp;чате со&nbsp;ссылкой на&nbsp;сайт спонсора.',
          },
        ],
        redirectBtn: 'Перейти на YouTube',
      },
      scopeTrovo: {
        heading: 'Перед тем, как начать',
        headline: 'Для корректной работы сервиса Uplify нам необходимо ваше разрешение на&nbsp;доступ к&nbsp;статистике вашего канала и&nbsp;отправку сообщений в&nbsp;чате.',
        info: 'Мы&nbsp;уважаем вашу конфиденциальность и&nbsp;крайне бережно относимся к&nbsp;вашим данным.',
        link: 'Why are we collecting this data?',
        desciptions: [
          {
            title: 'Получение адреса электронной почты',
            text: 'Email запрашивается Uplify для подтверждения регистрации и&nbsp;отправки важных уведомлений.',
          },
          {
            title: 'Просмотр информации о&nbsp;канале',
            text: 'Доступ только для чтения. Позволяет Uplify оптимизировать рекламные показы.',
          },
          {
            title: 'Отправка сообщений в&nbsp;чат',
            text: 'Позволяет отправлять сообщения в&nbsp;чате со&nbsp;ссылкой на&nbsp;сайт рекламодателя.',
          },
        ],
        redirectBtn: 'Go to Trovo',
      },
    },
    streamer: {
      home: {
        header: {
          greeting: 'Hi',
          introduction: 'I\'m Constantine from Uplify. I\'ll help you get to know your viewers better and start earning from live broadcasts.',
          letsDoIt: 'Let\'s do it!',
        },
        about: {
          label: 'WHO WE ARE',
          title: 'Uplify is a platform to work with your sponsorships directly',
          description: 'You have reached the open beta testing for English-speaking creators. Connect our widget, and soon you can start monetizing your streams.',
          howItWorks: 'See how it works',
          setup: 'Set up Uplify',
          statistic: {
            campaigns: {
              title: 'We complete more than',
              amount: '50 ad<br>campaigns',
            },
            payouts: {
              title: 'We payout more than',
              amount: '$400,000<br>for creators',
            },
          },
          howUplifyWorks: 'How Uplify works',
        },
        next: {
          label: 'WHAT\'S NEXT',
          description: 'Once you complete, we\'ll start analyzing your audience and find the right advertisers in the next 2 weeks. Make yourself at home!',
          steps: {
            first: 'Visit any page and <a href="https://help.uplify.app" target="_blank">read how our service works →</a>',
            second: 'Join to our creators community at <a href="https://discord.gg/uplify" target="_blank">Discord →</a>',
            third: 'Write to me with any questions via chat',
          },
        },
      },
      dashboard: {
        dashboard: 'Dashboard',
        greeting: 'С возвращением, {name} 👋',
        setup: {
          finishYourSetup: 'Завершите настройку виджета и чат-бота',
          stepsLeft: 'шага осталось | шаг остался | шага осталось | шагов осталось',
          aboutXMin: 'Около {min} мин',
          steps: {
            fillYourProfile: 'Заполни свой профиль',
            configureWidget: 'Настрой виджет в OBS',
            connectChatbot: 'Подключи чат-бота ',
            getFirstSponsorship: 'Получи первую рекламную интеграцию',
            freemium: 'Зарабатывайте больше с сообществом',
          },
          guides: {
            fillYourProfile: 'https://www.youtube-nocookie.com/embed/ykLXIguDxeA?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            configureWidget: 'https://www.youtube-nocookie.com/embed/7yTaaRcvnNQ?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            connectChatbot: 'https://www.youtube-nocookie.com/embed/TKvlptfQ0ZE?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1',
            getFirstSponsorship: 'https://fanatical-fly-511.notion.site/1cdf986f3495408fb5646d9a53b2dce0',
            freemium: 'https://fanatical-fly-511.notion.site/Streamer-support-page-6c5d0c3b3e62448e94aa2ce1223e4986',
          },
          stepByStepGuide: 'Пошаговая инструкция',
          closeChecklist: 'Закрыть',
        },
        values: {
          estimatedEarnings: 'Ожидаемый доход за&nbsp;{month}',
          turnOnUplifyPromo: 'Проверь свой кошелек',
          earnedByReferalls: 'Заработано на рефералах',
          getReferalLink: 'Получить реферальную ссылку',
          newCampaignsComingSoon: 'Новые кампании скоро появятся',
          activeCampaignsToday: 'активных кампаний сегодня | активная кампания сегодня | активные кампании сегодня | активных кампаний сегодня',
          goToCampaigns: 'Перейти к кампаниям',
          earnedByFreemium: 'Заработано на Uplify Link',
          freemiumLink: 'Настроить цель',
        },
        resources: {
          discordText: 'Присоединяйся к нашему комьюнити в Discord и делись своим опытом',
          blogText: 'Получай последние обновления и советы авторов в нашем блоге',
          helpCenterText: 'Узнай все о Uplify в разделе «Помощь»',
        },
        advice: {
          heading: 'ПОЧЕМУ ЭТО ВАЖНО?',
          title: 'Получайте от 10.000 ₽ в месяц',
          description: 'Для того чтобы начать монетизировать свой контент и получить доступ к рекламным интеграциям, нужно выполнить ',
          steps: '{n} шагов | {n} шаг | {n} шагa | {n} шагов',
          descriptionEnd: 'Давай сделаем это!',
        },
        levels: {
          heading: 'Твой прогресс',
          goals: 'Цели {level} уровня',
          perks: 'Бонусы {level} уровня',
          lvl: 'УР',
          days: 'дней',
          discord: {
            active: 'Добавлен',
            notActive: 'Добавить',
          },
          twitch: {
            active: 'Активен',
            notActive: 'Настроить',
          },
          advice: {
            label: 'об уровнях',
            title: 'Переходи на новый уровень',
            description: 'Программа лояльности Uplify - это серия поэтапных целей, которые вы можете достичь, занимаясь стримингом и монитируя свой контент на одной из поддерживаемых платформ. По мере того как вы будете расти и получать новые уровни, вам будут доступны новые возможности.',
            link: {
              text: 'Об уровнях',
              url: 'https://fanatical-fly-511.notion.site/160145bb5aa246a1a87c287790ff8faa',
            },
          },
        },
      },
      campaigns: {
        header: {
          title: 'Спонсорские кампании',
        },
        analytics: {
          returnBtn: 'Все кампании',
          reportBtn: {
            title: 'Отчет о кампании',
            shortTitle: 'Отчет',
            loading: 'Загрузка',
            noData: 'Нет данных',
          },
          fields: {
            clicks: 'Клики',
            ctr: 'CTR',
            impressions: 'Просмотры',
            botClicks: 'Фрод-клики',
            date: 'Дата',
          },
        },
        tabs: {
          current: 'Текущие',
          completed: 'Завершённые',
        },
        platforms: {
          title: 'Текущая активная платформа монетизации',
          link: {
            text: 'Сменить платформу',
          },
        },
        freemium: {
          title: 'Больше способов заработать',
          msg: {
            title: 'ПОВЫШАЙТЕ ДОХОД С UPLIFY LINK',
            text: 'Откройте для себя источник регулярного дохода, вовлечения и&nbsp;значимых отношений со&nbsp;зрителями. Начните зарабатывать до&nbsp;70% больше, пригласив своих зрителей поддержать вас прямо сейчас.',
            link: {
              text: 'Настроить Uplify.link',
            },
            getAccess: 'Получить доступ',
          },
        },
        active: {
          title: 'Активные кампании',
          description: 'Ваши спонсорские кампании с подробной статистикой в реальном времени.',
        },
        inactive: {
          title: 'Неактивные кампании',
          description: 'Согласно игнорируемым категориями, либо выключенные вами или модератором.',
          link: 'Напишите нам, если это ошибка',
          linkHref: 'https://discord.gg/uplify',
        },
        future: {
          title: 'Будущие кампании',
          description: 'Кампании доступные в ближайшее время.',
        },
        notAvailable: {
          title: 'Недоступные кампании',
          description: 'Кампании недоступные вам, либо выключенные модератором. Напишите в поддержку, если это ошибка.',
        },
        advice: {
          title: 'Важно знать',
          dailyLimit: {
            title: 'Дневной лимит',
            description: 'максимальное количество просмотров, доступных пользователю в день.',
          },
          dailyActionLimit: {
            title: 'Дневной лимит действий',
            description: 'максимальное количество действий, доступных всем пользователям ежедневно. Обратите внимание, что действия, превышающие дневные лимиты, оплачиваться не будут.',
          },
          potentialIncome: {
            title: 'Потенциальный доход',
            description: 'потенциальная сумма, которую можно заработать на кампании.',
            link: 'Подробнее о моделях оплаты и из чего формируется доход',
          },
          ctr: {
            title: 'CTR',
            description: 'показатель кликабельности кампаний на трансляции. Чем цифра больше, тем лучше.',
            link: 'Как повысить CTR?',
          },
        },
        none: [
          'Спонсорские кампании скоро появятся',
          'Кампаний нет, но вы держитесь',
          'Здесь будут ваши Кампании',
          'Сегодня рекламодатели запускают гуся',
          'Прекрасная кампания будущего. Скоро.',
        ],
      },
      widget: {
        header: {
          title: 'Настройки',
          widget: 'Виджет',
          chatbot: 'Чат-бот',
          extension: 'Расширение',
          stream: 'Трансляция',
          disabled: 'OFF',
          enabled: 'ON',
        },
        widgetPlatform: {
          title: 'Платформа для монетизации',
          description: 'Выбери платформу, где Uplify будет показывать кампании, собирать статистику и платить за просмотры. Выбор платформы влияет на доступные кампании и модули виджета.',
        },
        widgetLink: {
          title: 'Ссылка для виджета',
          description: 'Единая ссылка для отображения спонсорских кампаний через OBS или другие программы для трансляций.',
          optionDrag: {
            label: 'Вариант 1. Перетащите в OBS',
            helpWithSetup: 'Руководство по настройке',
            warn: 'Перетаскивание в OBS не будет работать, если OBS работает от имени администратора.',
            btn: 'Перетащите в OBS',
          },
          optionLink: {
            label: 'Вариант 2: Настройка виджета с нуля',
            helpWithSetup: 'Руководство по настройке',
            warn: 'Убедитесь, что виджет установлен на всю видимую область в вашей программе для трансляций и находится на верхнем слое. Следуйте руководству.',
          },
          preview: 'Отобразите тестовую анимацию в программе для трансляций.',
        },
        widgetDelay: {
          title: 'Задержка трансляции',
          new: 'new',
          description: 'Установите время задержки трансляции для корректной работы сервиса и уведомлений чат-бота.',
          howItWorks: 'Как это работает',
          fieldLabel: 'Время задержки (от 0 до 1800 с)',
        },
        chatbot: {
          title: 'Настройка чат-бота',
          description: 'Чат-бот используется для отправки рекламных сообщений и проведения опросов. Настройка является обязательной.',
          surveyDescription: 'Проблема с настройкой Nightbot или конфликт с&nbsp;другим чат-ботом?',
          wantAnotherChatbot: 'Хочу использовать другой чат-бот',
          shareChatbotSetup: 'Пожалуйста, поделитесь с нами своим опытом по настройке чатбота.',
          goThroughTheSurvey: 'Пройти опрос',
          helpWithSetup: 'Помощь в настройке',
          connectNightbot: 'Подключить Nightbot',
          disconnectNightbot: 'Отключить Nightbot',
          checkChatbot: 'Проверить чат-бота',
          sendMessage: 'Отправить сообщение',
          messageSent: 'Сообщение отправлено',
          error: 'Перед подключением бота, <a href="https://nightbot.tv/dashboard" target="_blank">перейди на&nbsp;сайт nighbot</a> и&nbsp;заверши игровую сессию.',
        },
        advertSettings: {
          title: 'Параметры показа кампаний',
          description: 'Выбор желаемой частоты и режима показа спонсорских блоков.',
          moreAboutFormats: 'Подробнее про форматы кампаний',
          bannerTimeout: 'Частота',
          adPlaybackMode: 'Режим показа',
          playbackModeManual: 'Ручной',
          playbackModeAuto: 'Авто',
          manualPlaybackTitle: 'Запуск кампаний',
          pictureInPicturePosition: 'Расположение формата Oверлей 15%',
          leaderboardPosition: 'Расположение широкого баннера',
          adsBlocksCountTitle: 'Количество спонсорских блоков',
          adsBlocksCountDuration: 'Длительность: {seconds} сек',
          advice: {
            title: 'Ручной запуск кампаний',
            firstString: 'Запустить кампанию вручную можно, когда виджет и чат-бот активны.',
            secondString: 'Как только кампании будут доступны, кнопка разблокируется.',
          },
        },
        panel: {
          title: 'Панель управления',
          mobileVersion: {
            title: 'Мобильная версия',
            description: 'Отсканируйте QR-код, чтобы открыть настройки показа кампаний на телефоне. Никому не показываейте этот код.',
          },
          obsVersion: {
            title: 'Версия для OBS',
            description: 'Панель управления можно добавить в OBS. Скопируйте ссылку и вставьте в разделе Вид → Док-панели → Пользовательские доки браузера. Никому не показывайте эту ссылку.',
          },
        },
        ignoreCategories: {
          title: 'Категории для игнорирования',
          description: 'Кампании из выбранных категорий будут автоматически выключены для показа в разделе Кампании.',
        },
        attention: {
          title: 'Настройка виджета и чат-бота',
          widgetDisabled: {
            title: 'Виджет отключен',
            reasons: {
              title: 'Возможны следующие причины:',
              broadcast: 'Выключена программа для трансляций.',
              notSetUp: 'Виджет не настроен в программе для трансляций.',
            },
          },
          chatbotDisabled: {
            title: 'Чат-бот отключен',
            reasons: {
              toBeConnected: 'Требуется подключить чат-бота для канала.',
              addChatbotAsModerator: 'Необходимо добавить чат-бота в качестве модератора',
            },
          },
        },
        twitchExtension: {
          title: 'Twitch расширение',
          description: 'Настройте расширение для Twitch, чтобы получить доступ к специальным спонсорским кампаниям.',
          status: 'Статус расширения',
          btns: {
            setup: 'Настроить расширение',
            check: 'Проверить статус',
          },
        },
      },
      wallet: {
        header: {
          title: 'Кошелек',
        },
        tabs: {
          overview: 'Обзор',
          history: 'История выплат',
        },
        balance: {
          heading: 'Ваш доход',
          title: 'Баланс',
          currentBalance: {
            label: 'Текущий баланс',
            popover: 'Сумма подтвержденного дохода',
          },
          minimumPayout: 'Минимальный размер выплаты',
          payoutAmount: 'Сумма для выплаты',
          nearestDatePayout: 'Ближайшая дата выплаты',
          howPayoutsWork: 'Как работают выплаты?',
          referralInfo: 'Поздравляем! Вы получили бонус за регистрацию {sum}. <br/><br/>Обратите внимание, что для сохранения этого бонуса по условиям программы требуют достижения определенного количества просмотров к {date}. <br/><br/>Просмотры: {imps} / {impsTotal}.',
          estimatedEarnings: {
            label: 'Ожидаемый доход за&nbsp;{month}',
            popover: 'Доход будет корректироваться после проверки на&nbsp;возможный фрод',
          },
          auditedEarnings: {
            label: 'Сумма на проверке',
            popover: 'Сумма, подлежащая проверке',
          },
          nextPayout: 'Сумма к выплате {date}',
        },
        paymentServices: {
          all: {
            title: 'Платежные сервисы',
            loading: 'загрузка',
            active: 'Активно для выплат',
            activate: 'Счёт по-умолчанию',
            learnMore: 'Подробнее про комиссии',
            paypal: 'PayPal',
            wireTransfer: 'Банковский перевод',
            paypalData: 'Подключение PayPal',
            billingsData: 'Привязка счета',
            whyDoWeNeedThisData: 'Зачем нам эти данные?',
            setUp: 'Настроить',
          },
          razorPay: {
            title: 'Платежные сервисы',
            loading: 'загрузка',
            learnMore: 'Подробнее про комиссии',
            setUp: 'Настроить',
            statuses: {
              yourStatus: 'Ваш статус',
              paymentMethod: 'Метод оплаты',
              payable: 'Payable',
              notPayable: 'Not payable',
              notAdd: 'Не добавлен',
            },
            form: {
              title: 'Billings data',
              whyDoWeNeedThisData: 'Why do we need this data?',
            },
          },
          tochkaBank: {
            title: 'Платежные сервисы',
            loading: 'загрузка',
            learnMore: 'Подробнее про комиссии',
            setUp: 'Настроить',
            employment: {
              type: 'Тип занятости',
              physical: 'Физическое лицо',
              physicalShort: 'Физлицо',
              selfEmployed: 'Самозанятый',
              taxHintSelf: 'Из-за изменения в законодательстве РФ для граждан РФ мы будем производить выплаты только на счета самозанятых. \n \n Самозанятые обязаны платить налоги с доходов в размере 6% из суммы вознаграждения. Самозанятый сам выплачивает налог по квитанции от налоговой.',
              taxHintPhysical: 'Физлица обязаны платить налоги с доходов в размере 13% из суммы вознаграждения. Мы сами переведем данную сумму в налоговую.',
              selfRegisterHint: 'Для начала подключите сервис-партнер «Я – сам» и обязательно дайте разрешение Uplify на взамодействие с сервисом. \n \n Интеграция с «Я – сам» позволяет автоматически производить выплаты, генерировать чеки, расчитывать и выплачивать налог с дохода автора.',
              formHint: 'Перед заполнением данных ниже убедитетесь, что вы предоставили разрешение Uplify на взаимодействие с сервисом «Я – сам». Пожалуйста, продублируйте данные в полях ниже для проверки интеграции с сервисом-партнером.',
              status: 'Статус',
              registered: 'Зарегистрирован',
              notRegistered: 'Не зарегистрирован',
              register: 'Подключить',
            },
            statuses: {
              yourStatus: 'Ваш статус',
              paymentMethod: 'Метод оплаты',
              payable: 'Payable',
              notPayable: 'Not payable',
              notAdd: 'Не добавлен',
            },
            form: {
              title: 'Привязка счёта',
              whyDoWeNeedThisData: 'Зачем нам эти данные?',
            },
            paymentStatus: {
              lastTransaction: 'Последний платёж',
              status: {
                inProgress: 'В обработке',
                error: 'Ошибка',
                success: 'Переведено',
              },
              fields: {
                amount: 'Сумма',
                date: 'Дата',
                status: 'Статус',
                methods: 'Способы оплаты',
              },
            },
          },
        },
        analytics: {
          heading: 'Аналитика дохода',
          categories: {
            awareness: 'Спонсорские форматы',
            performance: 'Интерактивные форматы',
            actions: 'Бонус за действия',
            freemium: 'Uplify Link',
            referrals: 'Рефералы',
            youtube_text: 'Youtube Text',
          },
          source: {
            title: 'Источники дохода',
          },
        },
        payoutHistory: {
          title: 'История выплат',
          columns: {
            date: 'Дата',
            amount: 'Сумма',
            service: 'Метод оплаты',
            invoice: 'Инвойс',
          },
          loading: 'загрузка',
          noData: 'Выплат ещё не было.',
        },
        tipaltiAdvice: {
          title: 'Для настройки платежных реквизитов для выплат необходимо иметь {amount} на балансе.',
          description: 'Настройка выплат доступна только тем авторам, которые хотя бы один раз заработали 80% от минимальной выплаты.',
        },
        tochkaAdvice: {
          title: 'Подключите один из сервисов оплаты',
          description: 'Нет подключенных сервисов. Подключите наиболее удобный вам для получения выплат.',
        },
      },
      profile: {
        header: {
          title: 'Настройки профиля',
        },
        tier: {
          whatIsTier: 'Что такое ранг?',
        },
        agency: {
          title: 'Агентство',
        },
        form: {
          howToChangeLanguage: 'Чтобы изменить язык трансляции и страну, напишите нам в чат или Discord.',
        },
        advice: {
          title: 'Требуется верификация почты',
          info: 'Информируем только о самых важных новостях и обновлениях.',
          noSpam: 'Никакого спама.',
          clickLink: 'Пожалуйста, нажмите на ссылку в письме, которое мы вам отправили для подтвержения.',
          link: 'Отправить повторно',
          linkClicked: 'Письмо отправлено',
        },
        platforms: {
          title: 'Подключенные платформы',
          writeUsToDetach: 'Напиши нам для отключения',
        },
        discord: {
          title: 'Присоединяйтесь к нашему серверу Discord, чтобы быть в курсе последних событий, получать поддержку и повышать уровень.',
          status: {
            caption: 'Ваш статус',
            connected: 'Подключен',
            notConnected: 'Не подключен',
          },
        },
        deactivation: {
          title: 'Деактивация аккаунта',
          textBlock1: 'Если вы&nbsp;хотите отдохнуть от&nbsp;Uplify, вы&nbsp;можете временно деактивировать свой аккаунт вместо удаления. Ваш профиль не&nbsp;будет отображаться на&nbsp;Uplify, пока вы&nbsp;не&nbsp;вернетесь.',
          textBlock2: 'Если вы&nbsp;продолжите, ваш профиль и&nbsp;детали аккаунта будут удалены <strong>{date}</strong> Ваш профиль будет скрыт на&nbsp;Uplify. Если вы&nbsp;передумаете, вы&nbsp;можете отозвать запрос до&nbsp;даты удаления, чтобы сохранить свой аккаунт.',
          deactivateBtn: 'Деактивировать',
          drawer: {
            title: 'Деактивировать аккаунт',
            subtitle: 'Вы уверены, что хотите деактивировать свой аккаунт?',
            textBlock1: 'При деактивации аккаунта Uplify следующие действия происходят с данными аккаунта:',
            textBlock2: [
              'Профиль, публичная страница, виджет скрыты от&nbsp;платформы и&nbsp;более не&nbsp;будут видны другим пользователям.',
              'Любые кампании будут недоступны.',
              'Баланс будет доступен до&nbsp;удаления аккаунта и&nbsp;выплачивается только при достижении минимального порога.',
              'Uplify будет хранить данные аккаунта во&nbsp;время его деактивации. Владелец аккаунта может в&nbsp;любое время восстановить аккаунт.',
              'Если аккаунт не&nbsp;будет восстановлен в&nbsp;течение определенного периода времени, Uplify удалит данные аккаунта навсегда. Процесс удаления может занять до&nbsp;40&nbsp;дней.',
              'Uplify также заявляет, что может сохранять определенную обезличенную информацию из&nbsp;деактивированных аккаунтов, если это необходимо для соблюдения юридических обязательств, разрешения споров, предотвращения мошенничества или обеспечения соблюдения своей политики.',
            ],
            textBlock3: 'Важно отметить, что деактивация аккаунта&nbsp;&mdash; это не&nbsp;то&nbsp;же самое, что удаление аккаунта, и&nbsp;это обратимый процесс, при котором аккаунт и&nbsp;его данные могут быть восстановлены.',
            confirmBtn: 'Подтвердить',
            cancelBtn: 'Отмена',
          },
        },
      },
      referrals: {
        header: {
          title: 'Реферральная программа',
        },
        unavailable: 'Нам очень жаль, реферальная программа не доступна для вас или вашего региона. <br>Напиши нам в <a href="https://discord.gg/uplify" target="_blank">Discord</a> или Intercom если у вас есть вопросы.',
        invite: {
          title: 'Пригласи друзей',
          description: 'Получи {referrer} на счёт за каждого приглашённого друга, а твой друг получит {referral} после завершения регистрации и показа кампаний.',
          invited: 'Приглашено',
          reward: 'Сумма для выплаты',
          link: 'Ссылка для приглашения',
          advice: {
            title: 'Как получить выплату?',
            description: 'Вы получите выплату когда друг зарегистрируется в Uplify, подтвердит почту и открутит кампаний на 5000 просмотров.',
            link: {
              label: 'Условия реферральной программы',
              url: '/',
            },
          },
        },
        promotion: {
          title: 'Продвижение Uplify',
          switchLabel: 'Продвигать Uplify на своей трансляции.',
          advice: {
            title: 'Как это работает',
            description: 'Ты можешь продвигать наш сервис на своей трасляции. Со всех регистраций ты получишь {referrer}, а рефералы получат {referral}. <br/><br/>Каждые два часа, во время трансляции, Uplify будет отправлять персонализованную рекламу, предлагающую присоединиться к нашему сервису.',
          },
          link: {
            label: 'Подробнее о продвижении',
            url: '/',
          },
          demo: {
            title: 'Нажми, чтобы увидеть пример баннера на трансляции.',
          },
        },
        panels: {
          title: 'Панели для Twitch',
          description: 'Поставь баннер под своей трансляцией, чтобы пригласить больше пользователей.',
        },
        history: {
          title: 'История рефералов',
          none: 'Вы еще никого не пригласили :(',
          columns: {
            creator: 'Реферал',
            date: 'Дата присоединения',
            status: 'Статус',
            impressions: 'Просмотров кампаний',
          },
          paid: 'Выплачен',
          waiting: 'Ожидание',
        },
        updated: {
          invite: {
            title: 'Пригласи друзей',
            description: 'Зарабатывайте 5% от дохода вашего друга.',
            invited: 'Приглашено',
            reward: 'Сумма для выплаты',
            link: 'Ссылка для приглашения',
            advice: {
              title: 'Как получить выплату?',
              description: 'Выплаты происходят ежедневно. Ваш друг должен зарегистрироваться в UpLify, завершить онбординг и начать показывать кампании.',
              link: {
                label: 'Условия реферральной программы',
                url: '/',
              },
            },
          },
          promotion: {
            title: 'Продвижение Uplify',
            switchLabel: 'Продвигать Uplify на своей трансляции.',
            advice: {
              title: 'Как это работает',
              description: 'Ты можешь продвигать наш сервис на своей трасляции. Со всех регистраций ты получишь 5% прибыли своих рефераллов. <br/><br/>Каждые два часа, во время трансляции, Uplify будет отправлять персонализованную рекламу, предлагающую присоединиться к нашему сервису.',
            },
            link: {
              label: 'Подробнее о продвижении',
              url: '/',
            },
            demo: {
              title: 'Нажми, чтобы увидеть пример баннера на трансляции.',
            },
          },
          history: {
            advice: {
              title: 'Важно знать',
              description: '<strong>Приглашен</strong>: пользователь зарегистрировался, но&nbsp;не&nbsp;настроил виджет и&nbsp;чат-бот. <br/><br/><strong>Начал показы</strong>: пользователь завершил регистрацию и&nbsp;готов показывать спонсорские кампании.',
            },
            invited: 'Приглашен',
            hasImpressions: 'Начал показы',
          },
        },
      },
      referralCheck: {
        title: 'Ваша реферальная ссылка отлично работает',
        text: 'Ваши друзья могут зарегистрироваться и получить бонус. <br><a href="https://discord.gg/uplify" target="_blank">Contact with us</a> если у вас есть вопросы.',
        btnText: 'Вернуться на домашнюю страницу',
      },
      extension: {
        header: {
          title: 'Twitch Расширение',
        },
        about: {
          title: 'О расширении',
          description: 'Соберите все ссылки на ваш контент в одном месте. С помощью блоков вы можете ссылаться на свои магазины, специальные предложения и социальные сети. Создайте свой собственный уникальный дизайн блока, добавьте ссылки, и все готово.',
          status: 'Статус расширения',
          statusTrue: 'Работает',
          statusFlase: 'Не работает',
        },
        settings: {
          title: 'Настройки',
          description: 'Создавайте свой уникальный дизайн блоков и добавляйте их на панель.',
          form: {
            block: {
              title: 'Блок',
              type: {
                label: 'Тип блока',
                placeholder: 'Выбеите тип блока',
              },
              link: {
                label: 'Перейти по ссылке',
                placeholder: 'Вставьте ссылку',
              },
              deleteBlock: 'Удалить блок',
            },
            addBlock: 'Добавить еще один блок',
          },
        },
        preview: {
          label: 'Предпоказ панели',
          title: 'Моё дерево ссылок',
          action: 'Нажмите чтобы продолжить',
        },
      },
      freemium: {
        title: 'Uplify Link',
        tabs: {
          setup: 'Настройка виджета',
          profile: 'Профиль',
          alerts: 'Уведомления',
          supporters: 'Аналитика',
          posts: 'Посты',
        },
        posts: {
          addNewBtn: 'Опубликовать пост',
          post: {
            createdAt: 'Опубликовано',
            edit: 'Редактировать',
            remove: 'Удалить',
          },
          sidebar: {
            title: {
              add: 'Добавить новый пост',
              edit: 'Редактировать пост',
            },
            markdown: {
              label: 'Markdown редактор',
              hint: 'Пример:<br><br>Мне нравится **<b>жирный шрифт</b>**.<br>*<i>Курсив</i>* тоже довольно неплох.<br><br>Рекомендую посетить [Uplify.link](https://uplify.link).',
            },
            embed: {
              label: 'Embed ссылка',
              hint: 'Some text',
            },
            btns: {
              post: 'Опубликовать',
              submit: 'Сохранить',
            },
          },
        },
        setup: {
          link: {
            title: 'Ваша страница Uplify Link',
            description: 'Добавьте эту ссылку в своих социальных сетях и поделитесь ею со своим сообществом. Зарабатывайте больше, регулярно делясь своей ссылкой.',
            errors: {
              unique: 'Креатор с таким именем уже существует',
              general: 'Некорректное имя',
            },
          },
          goal: {
            title: 'Укажите цель',
            description: 'Цели обычно привлекают внимание и мотивируют ваших зрителей. Добавьте цель и позвольте вашему сообществу помочь вам в ее достижении.',
            addGoal: 'Добавить цель',
            reachedOf: '1 000 из 200 000 достигнуто!',
          },
          goalForm: {
            title: 'Создать новую цель',
            editTitle: 'Редактировать цель',
            fields: {
              title: {
                caption: 'Название цели',
                placeholder: 'например, новая веб-камера',
              },
              description: {
                caption: 'Краткое описание цели',
                placeholder: 'Когда я соберу 20 000 баллов, я куплю новую веб-камеру, чтобы вывести свои трансляции на новый уровень.',
                hint: 'Дайте краткое описание причин, почему вам нужно достичь своей цели.',
              },
              amount: {
                caption: 'Сумма цели',
                hint: 'Задайте сумму без знака валюты, например, 100. Прогресс цели будет отображаться в процентах.',
              },
              progress: {
                caption: 'Текущий прогресс',
                hint: 'Введите сумму, которую вы уже собрали для достижения этой цели. Введите 0, если вы начинаете с нуля.',
              },
              publicAmount: {
                caption: 'Показать сумму по цели всем',
                hint: 'Показать общую целевую сумму на своей личной странице.',
              },
            },
            statusMessage: {
              success: 'Цель обновлена',
              fail: 'Ошибка обновления цели',
            },
          },
          poll: {
            title: 'Создайте новый опрос',
            description: 'Опросы помогают лучше вовлечь вашу аудиторию, что одновременно повышает уровень вовлеченности и заработок. Зрители должны посмотреть рекламу перед голосованием, а вы получите доход от показанной рекламы.',
            addPoll: 'Добавить опрос',
            duration: 'Длительность',
            votes: 'Голоса - осталось {num}',
          },
          pollForm: {
            title: 'Создать новый опрос',
            editTitle: 'Редактировать опрос',
            responsesTitle: 'Ответы',
            responsesTitleMin: '(минимум 2)',
            fields: {
              question: {
                caption: 'Вопрос',
                placeholder: 'например, в какую игру мне стоить играть дальше?',
              },
              answer: {
                caption: 'Ответ',
              },
              condition: {
                caption: 'Режим опроса',
                hint: 'Выберите условие завершения: по времени или количеству голосов',
                duration: 'Минуты',
                maxVotes: 'Голоса',
              },
              duration: {
                caption: 'Время в минутах',
                hint: 'Продолжительность сбора ответов по вашему опросу.',
              },
              maxVotes: {
                caption: 'Цель голосов',
                hint: 'Опрос завершится, когда получим столько голосов',
              },
            },
            statusMessage: {
              success: 'Опрос обновлен',
              fail: 'Ошибка обновления опроса',
            },
          },
        },
        profile: {
          telegram: {
            title: 'Автоматический постинг',
            description: 'Подключите социальную сеть и&nbsp;посты будут автоматически появляться на&nbsp;вашей странице Uplify Link.',
            input: {
              title: 'Telegram channel',
              description: 'Добавьте ссылку на&nbsp;свой канал c @ и&nbsp;назначьте нашего бота @UplifyBot администратором.',
              label: 'Ссылка',
              placeholder: '@my_channel',
            },
            errors: {
              channelExists: 'Канала с таким названием нет',
              invalidName: 'Неправильное название канала',
            },
          },
          about: {
            title: 'О себе',
            description: 'Добавьте данные для своего профиля',
            placeholder: 'Просто несколько слов',
            preview: 'Превью',
            advice: {
              label: 'ПОДСКАЗКА',
              title: 'Вы можете использовать Markdown',
              description: 'Пример:<br><br>Мне нравится **<b>жирный шрифт</b>**.<br>*<i>Курсив</i>* тоже довольно неплох.<br><br>Рекомендую посетить [Uplify.link](https://uplify.link).',
            },
            ai: {
              label: 'Cгенерированный ИИ текст вашего профиля',
              delete: 'Удалить',
              edit: 'Редактировать',
              like: 'Нравится',
              suggestionFull: 'Вот что ИИ думает о {name}',
              suggestionEmptyMale: '{name} ничего не написал о себе, но вот что может сказать ИИ',
              suggestionEmptyFemale: '{name} ничего не написала о себе, но вот что может сказать ИИ',
            },
          },
          gear: {
            title: 'Оборудование и настройки',
            description: 'Расскажите своей аудитории, какое оборудование для стримов вы&nbsp;используете',
            form: {
              addBlock: 'Добавить еще один блок',
              deleteBlock: 'Удалить блок',
              block: {
                title: 'Оборудование',
                category: {
                  label: 'Категория',
                  placeholder: 'Укажите категорию',
                },
                description: {
                  label: 'Описание',
                  placeholder: 'Опишите оборудование',
                },
                link: {
                  label: 'Ссылка',
                  placeholder: 'Введите ссылку',
                },
                sku: {
                  label: 'SKU',
                  placeholder: '',
                },
              },
            },
            advice: {
              label: 'Подсказка',
              title: 'Виджет Яндекс.Маркет',
              text: 'Проверяйте наличие товаров на&nbsp;Яндекс.Маркете. Если отсутствует хотя&nbsp;бы один, вместо ваших товаров будет показан виджет с&nbsp;похожими предложениями.',
            },
          },
          social: {
            title: 'Ссылки на соцсети',
            description: 'Поделитесь ссылками на социальные сети со своей аудиторией',
            form: {
              addBlock: 'Добавить еще один блок',
              deleteBlock: 'Удалить блок',
              block: {
                title: 'Ссылка',
                category: {
                  label: 'Социальная сеть',
                  placeholder: 'Выберите социальную сеть',
                },
                description: {
                  label: 'URL',
                  placeholder: 'Вставьте ссылку',
                },
              },
            },
          },
          theme: {
            title: 'Тема',
            description: 'Выберите цветовую тему вашей страницы Uplify Link',
            select: {
              label: 'Тема',
              options: {
                light: 'Светлая',
                dark: 'Темная',
              },
            },
          },
          banner: {
            title: 'Баннер профиля',
            description: 'Настройте баннер своего профиля на личной странице',
            label: 'Загрузите PNG, JPG до 5 МБ. Размер изображения должен быть не менее 1376x300 пикселей.',
          },
        },
        alerts: {
          supportAlert: 'Оповещение при посещении страницы',
          goalAlert: 'Оповещение о достижении цели',
          pollAlert: 'Оповещение о проведении опроса',
          chatAlert: 'Сообщение в чате',
          preview: 'Предпросмотр',
          alertPreview: {
            title: 'Предпросмотр',
            description: 'Настройте виджет в OBS (OBS Studio, Twitch Studio или Xsplit) и протестируйте оповещения перед прямой трансляцией.',
            sendTest: 'Отправить превью',
          },
          chatBotReminder: 'Требуется настройка чат-бота',
          fields: {
            messageDuration: {
              caption: 'Время отображения оповещения',
              hint: 'Как долго оповещение будет отображаться в прямом эфире.',
            },
            supportAlertPosition: {
              caption: 'Позиция оповещения о поддержке',
              hint: 'Указывает, где на экране будет отображаться оповещение.',
            },
            goalAlertPosition: {
              caption: 'Позиция оповещения о цели',
              hint: 'Указывает, где на экране будет отображаться оповещение.',
            },
            pollAlertPosition: {
              caption: 'Позиция оповещения об опросе',
              hint: 'Указывает, где на экране будет отображаться оповещение.',
            },
            chatAlert: {
              caption: 'Включить оповещения в чате',
              hint: 'Указывает, показывать или нет интерактивные уведомления во время прямой трансляции.',
            },
          },
          statusMessage: {
            success: 'Настройки обновлены',
            fail: 'Ошибка обновления настроек',
          },
        },
        supporters: {
          stats: {
            supporters: 'Спонсоров за месяц',
            revenueDay: 'Примерный доход за сегодня',
            cpm: 'Средняя цена за 1000 просмотров',
            points: 'Баллы за месяц',
            revenueMonth: 'Ожидаемый доход за месяц',
            impressions: 'Показов доставлено',
          },
          history: {
            supporters: 'Участники',
            supportedYou: 'поддержал вас!',
            noSupportersTitle: 'Вас пока никто не поддержал',
            noSupportersMessage: 'Побуждайте зрителей посетить вашу страницу, чтобы получить первые деньги за просмотр вашей страницы. ',
            impressions: '{imps} просмотров | {imps} просмотр | {imps} просмотра | {imps} просмотров',
            targets: {
              today: 'Сегодня',
              yesterday: 'Вчера',
              week: 'Эта неделя',
              month: 'Этот месяц',
              allTime: 'За все время',
            },
            columns: {
              id: '#',
              name: 'Имя',
              contribution: 'Баллы',
            },
            cacheTimeLeft: 'Этот рейтинг обновится через {min} мин.',
          },
        },
        widgetPosition: {
          leftTop: 'Левый верхний угол',
          rightTop: 'Правый верхний угол',
          leftBottom: 'Левый нижний угол',
          rightBottom: 'Правый нижний угол',
          disabled: 'OFF',
        },
        chatbotMsgInterval: {
          every15mins: 'Каждые 15 минут',
          every30mins: 'Каждые 30 минут',
          everyHour: 'Каждый час',
          disabled: 'OFF',
        },
      },
      deactivated: {
        title: 'Ваш аккаунт деактивирован',
        headline: 'Ваш профиль и&nbsp;данные аккаунта будут скрыты&nbsp;до <strong>{date}</strong> и&nbsp;удалены в&nbsp;этот день.',
        textBlock1: 'Ваша аккаунт Uplify деактивирован. Что это значит?',
        textBlock2: [
          'Профиль, публичная страница, виджет скрыты от&nbsp;платформы и&nbsp;более не&nbsp;будут видны другим пользователям.',
          'Любые кампании будут недоступны.',
          'Баланс будет доступен до&nbsp;удаления аккаунта и&nbsp;выплачивается только при достижении минимального порога.',
          'Uplify будет хранить данные аккаунта во&nbsp;время его деактивации. Владелец аккаунта может в&nbsp;любое время восстановить аккаунт.',
          'Если аккаунт не&nbsp;будет восстановлен в&nbsp;течение определенного периода времени, Uplify удалит данные аккаунта навсегда. Процесс удаления может занять до&nbsp;40&nbsp;дней.',
          'Uplify также заявляет, что может сохранять определенную обезличенную информацию из&nbsp;деактивированных аккаунтов, если это необходимо для соблюдения юридических обязательств, разрешения споров, предотвращения мошенничества или обеспечения соблюдения своей политики.',
        ],
        revokeBtn: 'Отозвать',
      },
    },
    partner: {
      campaigns: {
        header: {
          title: {
            'brand-awareness': 'Спонсорские кампании',
            performance: 'Интерактивные кампании',
            preroll: 'Preroll кампании',
          },
        },
        balance: 'Текущий баланс',
        tabs: {
          'brand-awareness': 'Awareness',
          performance: 'Interactive',
          preroll: 'Preroll',
        },
        types: {
          campaign: 'Кампании',
          group: 'Группы',
          creative: 'Креативы',
        },
        tables: {
          actions: {
            downloadReport: 'Скачать отчет',
            viewReport: 'Смотреть отчёт',
            duplicate: 'Дублировать',
            remove: 'Удалить',
          },
          columns: {
            name: 'Название',
            state: 'Статус',
            disabledStatusReason: {
              closed: 'Обратитесь в поддержку для включения',
              parentIsDisabled: 'Выключен родитель',
            },
            clicks: 'Клики',
            ctr: 'CTR',
            impressions: 'Просмотры',
            reach: 'Охват',
            id: 'ID',
            relatedCampaign: 'Связанная кампания',
            relatedGroup: 'Связанная группа',
            format: 'Формат',
            budget: 'Бюджет',
            budgetUsed: 'Использованный бюджет',
            totalBudget: 'Общий бюджет',
            advertiser: 'Рекламодатель',
            startedAt: 'Дата начала',
            endedAt: 'Дата окончания',
            platform: 'Платформа',
            moderation: 'Модерация',
            moderationStatus: {
              pending: 'В процессе',
              complete: 'Пройдена',
            },
            filterLabel: 'Настройка колонок',
            channels: 'Channels',
            daysRemaining: 'Осталось дней',
            externalId: 'Внешний ID',
            bid_cap: 'Bid cap',
            bid_cpa: 'Bid CPA',
            actions: 'Actions',
          },
          noCampaigns: 'Вы ещё не создали ни одной кампании',
          noGroups: 'Вы ещё не создали ни одной группы',
          noCreatives: 'Вы ещё не создали ни одного креатива',
        },
        newCreative: {
          title: 'Новый креатив',
          description: 'Выберите группу, которой будет принадлежать креатив',
        },
        newGroup: {
          title: 'Новая группа',
          description: 'Выберите кампанию, которой будет принадлежать группа',
        },
      },
      balance: {
        header: {
          title: 'Баланс',
        },
        info: {
          title: 'Текущий баланс',
          howFundUpBalance: 'Как пополнить баланс?',
        },
        history: {
          title: 'История поступлений',
          table: {
            columns: {
              date: 'Дата',
              amount: 'Сумма',
              service: 'Сервис',
            },
          },
          noPayments: 'Поступлений еще не было',
        },
      },
      creators: {
        header: {
          title: 'Агентство',
        },
        tabs: {
          overview: 'Обзор',
          history: 'История транзакций',
          campaignsActive: 'Кампании',
          campaignsClosed: 'Завершенные',
          creators: 'Авторы',
          billing: 'Выставление счетов',
        },
        invite: {
          title: 'Пригласить авторов',
          invited: 'Приглашенные авторы',
          amount: 'Сумма для вывода',
          link: 'Пригласительная ссылка',
          advice: {
            title: 'Получите долю дохода',
            description: 'Получайте долю дохода от ваших авторов, которых вы пригласили, пока они активны на Uplify.',
            link: {
              url: '/',
              label: 'Как это работает',
            },
          },
        },
        creatorsList: 'Список авторов',
        billing: {
          companyInformation: {
            title: 'Информация о компании',
            name: {
              label: 'Имя',
              placeholder: 'Название компании',
            },
            email: {
              label: 'Электронная почта',
              placeholder: 'email@email.com',
            },
            address: {
              label: 'Адрес',
              placeholder: 'Адрес компании',
            },
            phone: {
              label: 'Телефон',
              placeholder: '+1 (123) 456 789',
            },
          },
          paymentDetails: {
            title: 'Детали оплаты',
            bankName: {
              label: 'Название банка',
              placeholder: '',
            },
            bankAccountName: {
              label: 'Название банковского счета',
              placeholder: '',
            },
            bankAccountHolderAddress: {
              label: 'Адрес владельца банковского счета',
              placeholder: '',
            },
            bankAddress: {
              label: 'Адрес банка',
              placeholder: '',
            },
            bankAccountNumber: {
              label: 'Номер банковского счета',
              placeholder: '',
            },
            BIC: {
              label: 'БИК/SWIFT-код',
              placeholder: '',
            },
            routingNumber: {
              label: 'Маршрутный номер',
              placeholder: '',
            },
          },
          invoices: {
            title: 'Счета-фактуры',
            date: 'Дата',
            amount: 'Количество',
            invoice: 'Счет',
          },
        },
        history: {
          title: 'История транзакций',
          payments: 'Транзакции за все время',
          none: 'Транзакций не было',
          columns: {
            date: 'Дата',
            payment: 'Payment per day',
            details: 'Детали',
          },
          detailed: {
            title: 'Детализация транзакций',
            description: 'авторы, которые принесли деньги в этот день.',
          },
        },
        list: {
          title: 'Список авторов',
          description: 'Список авторов, которых вы пригласили. Платежи за все время ',
          none: 'Авторов нет',
          columns: {
            creator: 'автор',
            lastActivity: 'Последний день активности',
            earnings: 'Заработок',
          },
        },
        creatorsPayout: {
          title: 'Выплаты авторам',
          description: 'Пожалуйста, введите сумму, которую ваши авторы будут получать, размещая ваши кампании. Укажите цену за тысячу просмотров (CPM).',
          link: {
            text: 'Подробнее о форматах креативов',
            href: 'https://help.uplify.app/en/articles/5640856-in-stream-campaign-formats',
          },
          thirdParty: {
            checkbox: 'Разрешить сторонним партнерам размещать кампании',
            popover: {
              label: 'Подсказка',
              text: 'Введите базовую сумму, включающую комиссию и выплаты авторам. Укажите стоимость тысячи просмотров (CPM).',
            },
          },
          comission: {
            label: 'Комиссия',
            popover: {
              label: 'Подсказка',
              text: 'Используется для кампаний с индивидуальными договоренностями со спонсорами или если не установлены выплаты авторам.',
            },
          },
          darkMarket: {
            checkbox: 'Включить повышенную ставку для сегмента дарк-маркет',
            popover: {
              label: 'Подсказка',
              text: 'Введите базовую сумму для сегмента дарк-маркет (гемблинг, казино, криптовалюта и т.д.) Укажите стоимость за тысячу просмотров (CPM).',
            },
          },
          cpaLabel: 'Выплаты авторам',
          darkMarketLabel: 'Цены сторонних партнеров (вкл. комиссию)',
          fields: {
            video: 'Оверлей 50%',
            custom: 'Кастом',
            pip: 'Оверлей 15%',
            interactive: 'Интерактив',
            preroll: 'Pre-Roll',
            cpa: 'CPA cost',
            cpc: 'CPC cost',
          },
        },
        creatorsTable: {
          columns: {
            creators: 'Автор',
            lastActivity: 'Последняя активность',
            balance: 'Баланс',
            campaign: 'Кампании',
          },
          status: {
            checkList: {
              label: 'Онбординг',
              text: 'Автор еще не завершил процесс онбординга.',
            },
            payable: {
              label: 'Проверьте настройки выплат',
              text: 'Автор еще не настроил данные для выплат.',
            },
            lowCtr: {
              label: 'Низкий CTR',
              text: 'Проблемы с CTR в кампаниях.',
            },
          },
          none: 'Нет данных',
        },
        settings: {
          title: 'Настройки',
          custom: {
            headline: 'Персональные выплаты',
            descr: 'Пожалуйста, введите сумму, которую {streamer} должен получить при размещении спонсорства. Укажите стоимость тысячи просмотров (CPM).',
          },
          darkMarket: {
            headline: 'Ставки для сегмента дарк-маркет',
          },
          cpaLabel: 'Выплаты автору',
          darkMarketLabel: 'Цены сторонних партнеров (вкл. комиссию)',
          fields: {
            video: 'Оверлей 50%',
            custom: 'Кастом',
            pip: 'Оверлей 15%',
            interactive: 'Интерактив',
          },
        },
        stats: {
          columns: {
            campaign: 'Кампания',
            creator: 'Автор',
            income: 'Доход',
            views: 'Просмотры',
            avgCtr: 'Ср. CTR',
            ctr: 'CTR',
            status: 'Статус',
          },
          none: 'Нет данных',
        },
        campaignCreators: {
          title: 'Отчет по кампании: {title}',
        },
        creatorCampaigns: {
          title: 'Кампании {streamer}',
        },
        categoriesStopList: {
          title: 'Стоп-лист категорий',
          description: 'Кампании из выбранных категорий будут автоматически отключены для просмотра вашими авторами.',
        },
        campaigns: {
          active: {
            title: 'Активные кампании',
            description: 'Список кампаний, доступных вашим авторам',
          },
          closed: {
            title: 'Завершенные камании',
            description: 'Список завершенных кампаний',
          },
          reportBtn: {
            full: 'Отчет о кампании',
            short: 'Отчет',
          },
          none: 'Спонсорские кампании скоро появятся',
        },
      },
      campaign: {
        defaultName: 'Название кампании',
        defaultNameNew: 'Новая кампания',
        group: 'Группа',
        loading: 'Загрузка кампании ..',
        type: {
          title: 'Тип кампании',
          description: 'Выберите тип кампании, соответствующий вашим маркетинговым целям.',
          comingSoon: 'Скоро будет доступно',
        },
        types: {
          awareness: {
            title: 'Sponsorship',
            description: 'Кампании с фиксированной стоимостью по CPM-модели',
          },
          brandLift: {
            title: 'Brand Lift',
            description: 'Исследование, которое позволяет оценить уровень  восприятия бренда или продукта',
          },
          task: {
            title: 'Task',
            description: 'Задание с индивидуальной стоимостью и прямым вовлечением авторов',
          },
          performance: {
            title: 'Interactive',
            description: 'Кампании с оплатой за определенные действия по CPA-модели',
          },
        },
        settings: {
          title: 'Настройки кампании',
          advice: {
            title: 'Что это за данные?',
            name: {
              title: 'Название кампании,',
              description: 'которое будет отображаться у авторов во время трансляции.',
            },
            currency: {
              title: 'Валюта,',
              description: 'в которой будет списываться размещение вашей кампании.',
            },
          },
          form: {
            name: {
              label: 'Название',
              placeholder: 'Например, Spring Promo',
            },
            description: {
              label: 'Описание кампании',
              placeholder: 'Опишите кампанию для авторов',
            },
            externalId: {
              label: 'Внешний ID',
              placeholder: 'Например, PF-CMP-1661693993',
            },
            category: {
              label: 'Категория',
              placeholder: 'Выберите категорию',
              noData: 'Нет категорий',
            },
            currency: {
              label: 'Валюта',
              placeholder: 'Выберите валюту',
            },
            schedule: {
              startedAtLabel: 'Дата начала',
              endedAtLabel: 'Дата окончания',
            },
            holding: {
              label: 'Холдинг рекламодателя',
              placeholder: 'Выберите холдинг',
            },
            advertiser: {
              label: 'Рекламодатель',
              placeholder: 'Выберите рекламодателя',
              noData: 'Нет данных',
            },
            status: {
              label: 'Статус',
            },
            awareness: {
              description: {
                label: 'Описание кампании',
                placeholder: 'Опишите кампанию для авторов',
              },
            },
            brandlift: {
              description: {
                label: 'Описание',
                placeholder: 'Опишите опрос для авторов',
              },
            },
            timezone: {
              label: 'Часовой пояс',
              placeholder: 'Часовой пояс',
            },
            formSendStatus: {
              updated: 'Кампания обновлен',
            },
          },
        },
        analytics: {
          title: 'Расширенные настройки',
          affiliateNetworks: {
            label: 'Сеть',
            notSelected: 'Никаких интеграций',
          },
          urlParams: {
            description: 'Дополнительные параметры для URL-адреса, которые позволяют  отслеживать эффективность кампаний.',
            paramBlock: 'Параметр',
            param: 'Параметр',
            name: 'Значение',
            addParam: 'Добавить ещё один параметр',
            deleteParam: 'Удалить параметр',
          },
          pixel: {
            description: 'Uplify Pixel помогает отслеживать действия пользователей на сайте рекламодателя, чтобы показывать рекламу эффективно.',
            label: 'Uplify Pixel',
            btn: 'Скопировать',
          },
        },
      },
      group: {
        defaultName: 'Название группы',
        defaultNameNew: 'Новая группа',
        loading: 'Загрузка группы ..',
        settings: {
          title: 'Настройка группы',
          description: 'Сгруппируйте ваши кампании по параметрам и выставите таргетинг, чтобы эффективно отcлеживать каждую из них.',
          form: {
            name: {
              title: 'Название группы',
              label: 'Название видно только вам',
              placeholder: 'Группа',
            },
            description: {
              title: 'Описание группы',
              label: 'Описание группы',
              placeholder: 'Опишите группу для автора',
            },
            altName: {
              addBtn: 'Добавить альтернативное название для авторов',
              label: 'Название кампании для авторов',
              placeholder: 'Например, Spring Promo',
            },
            externalId: {
              title: 'External ID',
              placeholder: 'Например, PF-CMP-1661693993',
            },
            platform: {
              title: 'Платформа для размещения',
            },
            format: {
              title: 'Формат',
              moreAbout: 'Подобнее про форматы креативов',
              formats: {
                fullscreen: {
                  label: 'Видео на весь экран',
                  description: 'Mid-roll размещенный в прямом потоке трансляции на полный экран',
                },
                smallVideo: {
                  label: 'Видео в отдельном окне',
                  description: 'Mid-roll размещенный в прямом потоке трансляции на 1/4 экрана',
                },
                custom: {
                  label: 'Кастомизированное решение',
                  description: 'Мультиформатный креатив в прямом потоке трансляции на любой видимой области экрана',
                },
              },
            },
            schedule: {
              title: 'Расписание',
              startedAtLabel: 'Дата начала',
              endedAtLabel: 'Дата окончания',
            },
            payType: {
              payPerImpression: 'Оплата за просмотры',
              payPerAction: 'Оплата за действие',
            },
            budget: {
              title: 'Бюджет',
              costPerUnitLabel: 'Стоимость спонсорской интерграции за единицу',
              impressionsLabel: 'Количество просмотров',
              totalBudget: 'Общий бюджет',
              costPerActionLabel: 'Cтоимость одного действия',
              budgetLabel: 'Бюджет',
              totalActions: 'Ожидаемое количество действий:',
              bidCap: 'Стоимость одной мили, CPM',
              impressions: 'Количество показов',
              cpc: 'Стоимость клика',
              clicks: 'Количество кликов',
              conversions: 'Количество конверсий',
              margin: 'Маржа Uplify',
              agencyCommission: 'Комиссия агентства',
              cpmPercent: 'Разделение бюджета, CPM',
              cpa: 'Стоимость конверсии',
              creatorsPayout: 'Выплата создателям',
              conversion: 'Количество конверсий',
              infoMessage: 'Только для внутреннего использования',
              dailyClickCap: 'Ежедневный лимит кликов',
              dailyConversionCap: 'Ежедневный лимит конверсии',
              creatorsCPM: 'Оплата создателям за CPM',
              creatorsCPA: 'Оплата создателям за конверсию',
            },
            targets: {
              title: 'Цели',
              ctrLabel: 'Целевой CTR',
              cpaLabel: 'Целевой CPA',
            },
            frequency: {
              title: 'Частота',
              standard: {
                label: 'Стандартная',
                description: 'Стандартная — рекомендуемая частота просмотров с оптимальным охватом: подходит для долгосрочных кампаний.',
              },
              accelerated: {
                label: 'Ускоренная',
                description: 'Ускоренная — частота просмотров с максимальным охватом: подходит для краткосрочных кампаний.',
              },
              customizable: {
                label: 'Настраиваемая',
                description: 'Настраиваемая — частота просмотров с учетом специальных параметров по охвату: подходит для нестандартных кампаний.',
              },
              impressionsCount: 'Количество вставок',
              period: 'Период',
            },
            status: {
              label: 'Статус',
            },
            timezone: {
              label: 'Часовой пояс',
              placeholder: 'Часовой пояс',
            },
            formSendStatus: {
              updated: 'Группа обновлена',
            },
          },
        },
        targeting: {
          title: 'Таргетинг',
          addition: {
            onlySelected: 'только выбранные',
            exceptSelected: 'все, кроме выбранных',
            excludeSelected: 'Исключить выбранные категории из таргетинга',
          },
          evaluation: {
            label: 'Оценка аудитории',
            description: 'Выберите язык трансляции, чтобы получить предварительную оценку по аудитории.',
            size: 'Размер',
            reach: 'Охват',
            streamersNumber: 'Количество авторов',
            howCalculated: 'Как считаются показатели?',
          },
          form: {
            agencies: {
              label: 'Настройка сети',
              placeholder: 'Выберите агентство',
              warning: {
                title: 'Ваша стоимость для CPM ниже цены сети',
                text: 'Введенное значение для CPM ниже стоимости, которую требует партнерская сеть. Вы&nbsp;сможете сохранить кампанию, но&nbsp;она не&nbsp;будет работать до&nbsp;тех пор, пока не&nbsp;будет исправлена стоимость.',
              },
            },
            streamers: {
              label: 'Выбор авторов',
              placeholder: 'Выберите авторов',
            },
            language: {
              label: 'Язык трансляции',
              placeholder: 'Выберите язык',
            },
            countries: {
              label: 'Страны',
              placeholder: 'Выберите страну',
            },
            gender: {
              label: 'Пол',
              placeholder: 'Выберите пол',
            },
            age: {
              label: 'Возраст авторов',
              fromPlaceholder: 'от',
              toPlaceholder: 'до',
            },
            ageRestrictions: {
              label: 'У автора включены возрастные ограничения',
            },
            tags: {
              label: 'Теги',
              placeholder: 'Выберите тег',
            },
          },
        },
        labels: {
          title: '<strong>Маркировка рекламы</strong>',
          fields: {
            chatbot: {
              label: 'Chatbot erid ID',
            },
            creative: {
              label: 'Creative erid ID',
            },
          },
        },
        chatBot: {
          title: 'Настройки чат-бота',
          form: {
            productUrl: {
              label: 'Ссылка на продукт',
              placeholder: 'Введите ссылку',
            },
            productUrlShort: {
              label: 'Короткая ссылка',
              placeholder: '',
            },
            chatBotText: {
              label: 'Текст для чат-бота',
              placeholder: 'Введите текст',
            },
          },
        },
        creative: {
          title: 'Креатив',
          form: {
            productUrl: {
              label: 'Ссылка на продукт',
              placeholder: 'Введите ссылку',
            },
            videoDescriptionText: {
              label: 'Текст описания',
              placeholder: 'Введите текст',
            },
          },
        },
        preview: {
          title: 'Требования',
          requirements: 'Разрешение: 1920 x 1080 px.<br/>Формат: MP4<br/>Размер: до {size} МБ',
          errors: {
            unknown: 'Неизвестная ошибка',
          },
        },
        messages: {
          groupUpdatedSuccesfully: 'Группа успешно обновлена',
        },
        analytics: {
          title: 'Настройка аналитики',
          pixelClicks: {
            label: 'Пиксель на клик',
            placeholder: 'Введите ссылку',
            addLabel: 'Добавить еще один трекер',
          },
          pixelClicksScripts: {
            label: 'Код пикселя',
            placeholder: 'Введите код',
          },
          erid: {
            title: 'ERID (только рынок СНГ)',
            label: 'erid ID',
            placeholder: '',
          },
        },
      },
      creative: {
        defaultName: 'Название креатива',
        defaultNameNew: 'Новый креатив',
        loading: 'Загрузка креатива ..',
        form: {
          status: {
            label: 'Статус',
          },
          name: {
            title: 'Название креатива',
            label: 'Название видно только вам',
            placeholder: 'Введите текст',
          },
          adtag: {
            title: 'Google Ad Tag',
            label: '',
            placeholder: '',
          },
          files: {
            title: 'Загрузка файлов',
            preview: 'Загрузка файла превью',
            formats: {
              video: 'Видео',
              videoOrImage: 'Видео или картинка',
              html5: 'HTML5 элемент',
              banner: 'Вспомогательный баннер',
            },
            requirements: {
              title: 'Технические требования',
              fullscreen: 'Длительность: 15 с<br>Разрешение: 1920 x 1080<br>Формат: H.264 (MP4, webm)<br>Аудио: -9dB<br>Битрейт: 2000 - 5500 kbps<br>Частота кадров: 24 – 30<br>Размер: до 10 МБ',
              leaderboard: 'Длительность: 15 с<br>Разрешение: 1920 x 270<br>Формат: H.264 (MP4, webm)<br>Аудио: отключено<br>Битрейт: 2000 – 5500 kbps<br>Частота кадров: 24 – 30<br>Размер: до 5 МБ',
              pip_video: 'Длительность: 15 с<br>Разрешение: от 550 x 310<br>до 1920 x 1080 пикс.<br>Формат: H.264 (MP4, webm)<br>Аудио: отключено<br>Битрейт: 2000 – 5500 kbps<br>Частота кадров: 24 – 30<br>Размер: до 5 МБ',
              custom: 'Длительность: 15 с<br>Разрешение: 1920 x 1080<br>Формат: PNG, JPG or GIF<br>Third party HTML: HTML5 zip file<br>Аудио: отключено<br>Частота кадров: 24 – 30<br>Размер: до 10 МБ',
            },
            instructions: {
              dragImage: 'Перетащите сюда картинку',
              dragImageOrVideo: 'Перетащите сюда картинку или видео',
              dragVideo: 'Перетащите сюда видео',
              dragZip: 'Перетащите сюда архив с файлами',
            },
            errors: {
              general: 'Загрузите валидный файл креатива',
            },
          },
          creativeManager: {
            messages: {
              deleted: 'Файл был удален',
            },
            errors: {
              unknown: 'Неизвестная ошибка',
            },
          },
          advice: {
            title: 'Превью креатива',
            description: 'Оцените, как будет выглядеть ваши кампании в месте размещения',
            button: 'Посмотреть превью',
          },
          data: {
            title: 'Данные для креатива',
            fields: {
              erid: {
                title: 'ERID (только рынок СНГ)',
                label: 'erid ID',
                placeholder: '',
              },
              productUrl: {
                label: 'Ссылка на ваш продукт',
                placeholder: 'Введите ссылку',
              },
              chatbotText: {
                label: 'Текст для чат-бота',
                placeholder: 'Введите текст',
              },
              companionHeading: {
                label: 'Заголовок сопутствующего баннера',
                placeholder: 'Введите заголовок',
              },
              companionText: {
                label: 'Текст сопутствующего баннера',
                placeholder: 'Введите текст',
              },
              companionCta: {
                label: 'Призыв к действию',
                placeholder: 'Введите текст',
              },
              qrCode: {
                label: 'Сгенерировать QR-код',
              },
              pixelClicks: {
                label: 'Пиксель на клик',
                placeholder: 'Введите ссылку',
              },
              pixelClicksScripts: {
                label: 'Код пикселя',
                placeholder: 'Введите код',
              },
              pixelImpressions: {
                label: 'Пиксель на просмотр',
                placeholder: 'Введите ссылку',
                addLabel: 'Добавить еще один трекер',
              },
              pixelInspections: {
                label: 'Пиксель на досмотр',
                placeholder: 'Введите ссылку',
                addLabel: 'Добавить еще один трекер',
              },
            },
          },
          labels: {
            title: '<strong>Маркировка рекламы</strong>',
            fields: {
              chatbot: {
                label: 'Chatbot erid ID',
              },
              creative: {
                label: 'Creative erid ID',
              },
            },
          },
          formSendStatus: {
            updated: 'Креатив обновлен',
          },
        },
      },
      profile: {
        header: {
          title: 'Профиль',
        },
        agency: {
          title: 'Агентство',
        },
        settings: {
          title: 'О себе',
        },
        password: {
          title: 'Смена пароля',
        },
      },
      analytics: {
        campaignOverview: 'Обзор кампании',
        creators: 'Авторы',
        categories: 'Категории',
        status: 'Статус',
        active: 'Активные',
        inactive: 'Неактивные',
        updated: 'Обновлено',
        downloadCSV: 'Скачать CSV',
        downloadExcel: 'Скачать Excel',
        impressions: 'Просмотры',
        CTR: 'CTR',
        spent: 'Потрачено',
        fills: 'Вставки',
        reach: 'Охват',
        channels: 'Каналы',
        avgCPM: 'Средний CPM',
        totalClicks: 'Всего кликов',
        clicks: 'Клики',
        adFormat: 'Формат',
        outOf: 'Из',
        creator: 'Автор',
        category: 'Категория',
        runATestCampaignToday: 'Запустите тестовую кампанию уже сегодня',
        viewability: 'Видимость',
        overviewTab: {
          title: 'Показатели кампании',
          performanceTitle: 'Показатели по дням',
          creativeStats: 'Статистика креативов',
          allCategories: 'Все категории',
          groupName: 'Название группы',
          creativeName: 'Название креатива',
          top5Channels: 'Топ 5 каналов',
          top5Categories: 'Топ 5 категорий',
          creatorsLinkCaption: 'Перейти ко всем авторам',
          categoriesLinkCaption: 'Перейти ко всем категориям',
        },
        creatorsTab: {
          title: 'Эффективность РК по авторам',
        },
        categoriesTab: {
          title: 'Эффективность РК по категориям',
          otherCategories: 'Другие категории',
        },
      },
      advertisers: {
        header: {
          title: 'Рекламодатели',
        },
        tabs: {
          advertisers: 'Рекламодатели',
          holdings: 'Холдинги',
        },
        advertisers: {
          table: {
            advertiser: 'Рекламодатели',
            currency: 'Валюта',
            balance: 'Баланс',
            links: 'Ссылки (Спонсорство)',
          },
          links: {
            campaigns: 'Кампании',
            groups: 'Группы',
            creatives: 'Креативы',
            holdings: 'Холдинги',
          },
        },
        holdings: {
          table: {
            holding: 'Название холдинга',
            description: 'Описание',
          },
        },
      },
    },
    widget: {
      adForSupport: 'Промокампания от',
      willStartIn: 'начнется через',
      poweredBy: 'Работает на Uplify',
      of: 'из',
      reloginMessage: {
        title: '{platform} обновился!',
        description: 'Ваша версия виджета устарела. Пожалуйста, выйдите и войдите заново в личный кабинет.',
        streamer: {
          label: 'Внимание',
          title: '{platform} обновился!',
          description: 'Ваша версия виджета устарела. Пожалуйста, выйдите и войдите заново в личный кабинет.',
          button: 'Перелогиниться',
        },
      },
      chatbotErrorMessage: {
        title: 'Чат-бот не работает',
        description: 'Пожалуйста, переподключите бота в&nbsp;личном кабинете. Пошаговая инструкция: fix.uplify.app/chatbot',
      },
      resolutionErrorMessage: {
        title: 'Виджет настроен неправильно',
        description: 'Пожалуйста, настройте виджет по&nbsp;инструкции: <span>fix.uplify.app/widget</span>',
      },
      tooManySubscribers: {
        title: 'Виджет заблокирован',
        description: 'Пожалуйста, удалите все копии виджета и обновите слой.',
      },
      platformDisclaimer: 'не спонсирует и не поддерживает эту промокампанию и не несет за нее ответственности.',
      platformDisclaimerYoutube: 'Это промо размещено автором независимо от YouTube.',
      freemium: {
        timeLeft: 'осталось минут: {time}',
        votesLeft: 'Голоса - осталось: {num}',
        pollWinner: 'Победитель',
        goalQrTitle: 'Поддержите меня',
        goalTopSuppoerters: {
          day: 'Топ за сегодня',
          week: 'Топ за неделю',
          quarter: 'Топ за все время',
          alltime: 'Топ за все время',
        },
        supporterWatcherSponsorVideo: 'посмотрел спонсорское видео',
        anonymous: 'Кто-то',
        supportedYou: 'поддержал тебя',
        chatbotMsgs: [
          'Поддержи меня просмотром рекламы {link}',
          'Тут изобрели донаты без денег {link}',
          'Теперь поддержать мой стрим просто: переходи по ссылке и посмотри рекламу {link}',
          'Донейшены, чаевые и пожертвования принимаются тут. Карточка не нужна {link}',
          'Поддержи меня еще, даже если уже переходил по ссылке {link}',
          'Карты банков не нужны, когда донаты бесплатные {link}',
          'Каждый переход по этой ссылке — ваша поддержка моего стрима {link}',
          'Донаты без SMS и регистраций — {link}',
        ],
      },
    },
    panel: {
      tabs: {
        widget: 'Настройки',
        campaigns: 'Кампании',
      },
      states: {
        title: 'Состояние модулей',
      },
      params: {
        title: 'Параметры показа кампаний',
        previewCaption: 'Показать превью',
        locationCaption: 'Позиция на стриме',
      },
      campaigns: {
        title: 'Доступные кампании',
        none: 'Нет доступных кампаний',
        comeBack: 'Сегодня все кампании закончились.<br>Приходи завтра!',
      },
      error: {
        title: 'Ссылка устарела',
        text: 'Перейдите в личный кабинет за новой ссылкой',
      },
      statusesMustBeOn: 'Для запуска кампаний, все модули должны быть активными',
      logger: {
        copy: 'Копировать лог',
        copied: 'Лог скопирован!',
      },
    },
    page404: {
      pageNotFound: 'Страница не найдена',
      back: 'Вернуться на главную',
      question: 'Возникла проблема?',
      chat: 'Напишите нам',
    },
  },
}

export default dictionary
